import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import CoursePage from "./pages/management/Course/CoursePage";
import CategoryPage from "./pages/management/CategoryPage";
import UserCourseScoringPage from "./pages/scoring/UserCourseScoringPage";
import UploadCourseScorePage from "./pages/scoring/UploadCourseScorePage";
import { RegisteredUserChart } from "./pages/charts/RegisteredUserChart";
import { CourseEnrollmentChart } from "./pages/charts/CourseEnrollmentChart";
import UserPage from "./pages/management/UserPage";
import TenantPage from "./pages/management/TenantPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/management/course" component={CoursePage} />
        <ContentRoute path="/management/user" component={UserPage} />
        <ContentRoute path="/management/tenant" component={TenantPage} />
        <ContentRoute path="/management/category" component={CategoryPage} />
        <ContentRoute path="/scoring/userscoring" component={UserCourseScoringPage} />
        <ContentRoute path="/scoring/uploadscore" component={UploadCourseScorePage} />
        <ContentRoute path="/chart/registereduser" component={RegisteredUserChart} />
        <ContentRoute path="/chart/enrollment" component={CourseEnrollmentChart} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
