import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { apiConfig } from "../../../config/api.config";
import { getUsers } from "../../../redux/managementCrud";
import { toCamel } from "../../../utils";
import NumberFormat from "react-number-format";

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 2,
    marginRight: 2,
    width: 200,
  },
  centerBox: {
    padding: 10,
    height: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
}

class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      order: {},
      offset: undefined,
      limit: 10,
      users: [],
      showCreateDialog: false,
      showEditDialog: false
    };
  }

  componentDidMount() {
  }

  handleCreateDialogClick() {
    this.setState({
      showCreateDialog: true
    });
  }

  handleCreateDialogClose() {
    this.setState({
      showCreateDialog: false
    });
  }

  handleEditDialogClick() {
    this.setState({
      showEditDialog: true
    });
  }

  hanldeEditDialogClose() {
    this.setState({
      showEditDialog: false
    });
  }

  fetchUsers() {
    const { filter, order, offset, limit } = this.state;
    getUsers(filter, order, offset, limit)
      .then(({ data }) => {
        if (data.Success) {
          this.setState({
            users: toCamel(data.Data)
          });
        }
      });
  }

  render() {
    const { classes } = this.props;
    const { users, showCreateDialog, showEditDialog } = this.state;

    return (
      <>
        <div className={`card card-custom `}>
          {/* Head */}
          <div className="card-header border-0 py-5">
            <div>
              <div>
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label font-weight-bolder text-dark">User</span>
                </h3>
              </div>
              <Box component="span" m={5}>
                <form style={{ display: "flex", flexWrap: "wrap" }}>
                  <FormControl>
                    <TextField
                      className={classes.textField}
                      id="search-name"
                      label="Search name"
                      type="search"
                      margin="dense"
                      variant="outlined"
                      size="small"
                      onChange={(e) => {
                        const { filter } = this.state;
                        if (e.target.value === '') {
                          filter.FullName = undefined;
                        } else {
                          filter.FullName = e.target.value;
                        }

                        this.setState({ filter });
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      className={classes.textField}
                      id="search-email"
                      label="Search email"
                      type="search"
                      margin="dense"
                      variant="outlined"
                      size="small"
                      onChange={(e) => {
                        const { filter } = this.state;
                        if (e.target.value === '') {
                          filter.EMailAddress = undefined;
                        } else {
                          filter.EMailAddress = e.target.value;
                        }

                        this.setState({ filter });
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <Box className={classes.centerBox}>
                      <Button className="btn btn-light-success font-weight-bolder mr-2" onClick={() => this.fetchUsers()}>Apply</Button>
                    </Box>
                  </FormControl>
                </form>
              </Box>
            </div>
          </div>
          {/* Body */}
          <div className="card-body pt-0 pb-3">
            <div className="tab-content">
              <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Full Name</span></th>
                      <th style={{ minWidth: "100px" }}>E-Mail</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(user =>
                      <tr>
                        <td className="pl-0 py-8">
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-50 symbol-light mr-4">
                              <span className="symbol-label">
                                <img alt={user.activeRID} src={`${apiConfig.apiUrl.acs}/picture/${user.activeRID}/thumb`} height={40}></img>
                              </span>
                            </div>
                            <div>
                              <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{user.fullName}</a>
                              <span className="text-muted font-weight-bold d-block">{user.hashCode}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {user.email}
                          </span>
                          <span className="text-muted font-weight-bold d-block">{user.eMailAddress}</span>
                        </td>
                      </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <Dialog open={showCreateDialog} onClose={this.handleCreateDialogClose.bind(this)}>
          <DialogTitle>Create Course</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please fill the form to add user score.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCreateDialogClose.bind(this)}>Cancel</Button>
            <Button onClick={this.handleCreateDialogClose.bind(this)}>Save</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(UserPage);