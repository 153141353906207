/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { isExpired } from "../utils";
import { apiConfig } from "../config/api.config";
import AuthCallbackPage from "./modules/Auth/pages/AuthCallbackPage";

export function Routes() {
    const {isAuthorized} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.authToken != null && isExpired(auth.tokenExpiryTime)
        }),
        shallowEqual
    );

    return (
        <Switch>
            <Route path="/auth/callback" component={AuthCallbackPage} />

            <Route path="/error" component={ErrorsPage}/>
            <Route path="/logout" component={Logout}/>


            {!isAuthorized ? (
                <Route component={() => {
                    window.location.href = `${apiConfig.apiUrl.skillcard}/users/auth?callbackUrl=http://sadmin.playbook.plus/auth/callback`;
                }}>
                </Route>
            ) : (
                <Layout>
                    <BasePage/>
                </Layout>
            )}
        </Switch>
    );
}
