import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Paper } from "@material-ui/core";

export class CreateCourseDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newCourse: {
        institutionID: 0,
        teacherID: 0,
        leaderID: 0,
      },
    };
  }

  render() {
    const { show, categories, competencies } = this.props;
    const { onClose, onSave } = this.props;
    const { newCourse } = this.state;

    return (
      <Dialog open={show} onClose={onClose}>
        <DialogTitle>Create Course</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill the form to create course.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => this.setState({ newCourse: { ...newCourse, title: e.target.value } })}
          />
          <TextField
            autoFocus
            margin="dense"
            id="slug"
            label="Slug"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => this.setState({ newCourse: { ...newCourse, slug: e.target.value } })}
          />
          <FormControl
            fullWidth
          >
            <InputLabel>Type</InputLabel>
            <Select
              id="type"
              onChange={(e) => this.setState({ newCourse: { ...newCourse, type: e.target.value } })}
            >
              <MenuItem value={"F"}>Kelas Mikro Gratis</MenuItem>
              <MenuItem value={"P"}>Kelas Mikro Plus</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
          >
            <InputLabel>Category</InputLabel>
            <Select
              id="category"
              onChange={(e) => this.setState({ newCourse: { ...newCourse, categoryID: e.target.value } })}
            >
              {categories.map(c => <MenuItem value={c.id}>{c.name}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
          >
            <InputLabel>Competency</InputLabel>
            <Select
              id="competency"
              onChange={(e) => this.setState({ newCourse: { ...newCourse, competencyID: e.target.value } })}
            >
              {competencies.map(c => <MenuItem value={c.id}>{c.name}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
          >
            <InputLabel>TargetCountry</InputLabel>
            <Select
              id="targetcountry"
              onChange={(e) => this.setState({ newCourse: { ...newCourse, targetCountry: e.target.value } })}
            >
              <MenuItem value={"ID"}>Indonesia</MenuItem>
              <MenuItem vlaue={"SG"}>Singapore</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
          >
            <InputLabel>Language</InputLabel>
            <Select
              id="language"
              onChange={(e) => this.setState({ newCourse: { ...newCourse, language: e.target.value } })}
            >
              <MenuItem value={"id"}>Bahasa</MenuItem>
              <MenuItem vlaue={"en"}>English</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
          >
            <InputLabel>Tier</InputLabel>
            <Select
              id="tier"
              onChange={(e) => this.setState({ newCourse: { ...newCourse, tier: e.target.value } })}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
          >
            <InputLabel>Target</InputLabel>
            <Select
              id="target"
              onChange={(e) => this.setState({ newCourse: { ...newCourse, target: e.target.value } })}
            >
              <MenuItem value={"PRO"}>PRO</MenuItem>
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => this.setState({ newCourse: { ...newCourse, description: e.target.value } })}
          />
          <TextField
            hidden={this.state.newCourse.type !== 'P'}
            autoFocus
            margin="dense"
            id="price"
            label="Price"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => this.setState({ newCourse: { ...newCourse, price: e.target.value } })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={() => onSave(newCourse)}>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}