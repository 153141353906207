import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Box, FormControlLabel, Snackbar } from "@material-ui/core";
import AutoComplete from "@material-ui/lab/Autocomplete";
import { apiConfig } from "../../../config/api.config";
import { getCategories, getCourses, getParticipants, updateUserScore } from "../../../redux/courseCrud";
import { toCamel } from "../../../utils";
import NumberFormat from "react-number-format";
import { withStyles } from "@material-ui/core";
import SnackbarContentWrapper from "../../components/SnackbarContentWrapper";

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 2,
    marginRight: 2,
    width: 500,
  },
  centerBox: {
    padding: 10,
    height: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
}

class UserCourseScoringPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      order: { TimeEnrolled: "DESC", Score: "ASC" },
      offset: undefined,
      limit: undefined,
      localFilter: {},
      courses: [],
      selectedCourse: null,
      courseParticipants: [],
      selectedParticipant: undefined,
      showEditDialog: false,
      openSnackbar: false,
      snackbarProperty: {
        variant: "error",
        message: "Unhandled error. Please contact Administrator."
      }
    };
  }

  componentDidMount() {
    this.fetchCourses();
  }

  handleEditDialogClick() {
    this.setState({
      showEditDialog: true
    });
  }

  handleEditDialogClose() {
    this.setState({
      showEditDialog: false
    });
  }

  onEditDialogSave() {
    const { selectedParticipant } = this.state;
    if (selectedParticipant) {
      const score = selectedParticipant.score;

      if (!score || isNaN(score)) {
        this.setState({
          openSnackbar: true,
          snackbarProperty: {
            variant: "error",
            message: "Invalid score. Score cannot be empty and must a number."
          }
        });
      } else if (score < 0 || score > 5) {
        this.setState({
          openSnackbar: true,
          snackbarProperty: {
            variant: "error",
            message: "Invalid score. Score must between 0 - 5."
          }
        });
      } else {
        const { selectedCourse } = this.state;
        updateUserScore(selectedParticipant.user.id, selectedCourse.id, score)
          .then(({ data }) => {
            if (data.Success) {
              this.fetchParticipants();
              this.setState({
                openSnackbar: true,
                snackbarProperty: {
                  variant: "success",
                  message: "Score has been updated successfully."
                },
                showEditDialog: false
              });
            }
          });
      }
    }
  }

  handleCloseSnackbar() {
    this.setState({
      openSnackbar: false
    });
  }

  fetchCourses(filter = {}, order = {}, offset = undefined, limit = 10) {
    getCourses(filter, order, offset, limit)
      .then(({ data }) => {
        if (data.Success) {
          this.setState({
            courses: toCamel(data.Data)
          });
        }
      });
  }

  fetchParticipants() {
    const { localFilter, selectedCourse, filter, order, offset, limit } = this.state;

    if (selectedCourse) {
      getParticipants(selectedCourse.id, filter, order, offset, limit)
        .then(({ data }) => {
          if (data.Success) {
            if (localFilter.fullName) {
              const wrappedData = toCamel(data.Data);
              const filtered = wrappedData.filter(value => value.user.fullName.toLowerCase().includes(localFilter.fullName));
              this.setState({
                courseParticipants: filtered
              });
            } else {
              this.setState({
                courseParticipants: toCamel(data.Data)
              });
            }
          }
        })
    }
  }

  render() {
    const { classes } = this.props;
    const { showEditDialog, openSnackbar, snackbarProperty } = this.state;
    const { courses, courseParticipants } = this.state;
    const { selectedParticipant } = this.state;

    return (
      <>
        <div className={`card card-custom `}>
          {/* Head */}
          <div className="card-header border-0 py-5">
            <div>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">User Course Scoring</span>
              </h3>
              <Box component="span" m={5}>
                <form style={{ display: "flex", flexWrap: "wrap" }}>
                  <FormControl>
                    <AutoComplete
                      options={courses}
                      getOptionLabel={(option) => option.title}
                      autoHighlight
                      renderInput={(params) =>
                        <TextField {...params}
                          className={classes.textField}
                          label="Search title"
                          margin="dense"
                          variant="outlined"
                          size="small"
                        />
                      }
                      onInputChange={(event) => {
                        const value = event.target.value;

                        const timeoutId = setTimeout(() => {
                          getCourses({ title: value }, {}, undefined, 10)
                            .then(({ data }) => {
                              if (data.Success) {
                                this.setState({
                                  courses: toCamel(data.Data)
                                });
                              }
                            });
                        }, 500);

                        return () => {
                          clearTimeout(timeoutId);
                        }
                      }}
                      onChange={(event, value) => {
                        this.setState({
                          selectedCourse: value
                        });
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      className={classes.textField}
                      id="search-name"
                      label="Search name"
                      type="search"
                      margin="dense"
                      variant="outlined"
                      size="small"
                      onChange={(e) => {
                        const { localFilter } = this.state;
                        if (e.target.value === '') {
                          localFilter.fullName = undefined;
                        } else {
                          localFilter.fullName = e.target.value.toLowerCase();
                        }

                        this.setState({ localFilter });
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <Box className={classes.centerBox}>
                      <Button className="btn btn-light-success font-weight-bolder mr-2" onClick={this.fetchParticipants.bind(this)}>Apply</Button>
                    </Box>
                  </FormControl>
                </form>
              </Box>
            </div>
          </div>
          {/* Body */}
          <div className="card-body pt-0 pb-3">
            <div className="tab-content">
              <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Title</span></th>
                      <th style={{ minWidth: "100px" }}>User</th>
                      <th style={{ minWidth: "100px" }}>Score</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {courseParticipants.map(participant =>
                      <tr>
                        <td className="pl-0 py-8">
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-50 symbol-light mr-4">
                              <span className="symbol-label">
                                <img src={`${apiConfig.apiUrl.acs}/picture/${participant.user.rid}/thumb`} height={40}></img>
                              </span>
                            </div>
                            <div>
                              <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{participant.user.fullName}</a>
                              <span className="text-muted font-weight-bold d-block">{participant.user.id}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {participant.timeEnrolled}
                          </span>
                          <span className="text-muted font-weight-bold d-block">{participant.isUserFinished === 1 ? "Finished" : "Ongoing"}</span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {participant.score ? participant.score : (<Button className="btn btn-light-success font-weight-bolder font-size-sm" onClick={() => this.setState({ showEditDialog: true, selectedParticipant: participant })}>Update</Button>)}
                          </span>
                          <span className="text-muted font-weight-bold d-block">{participant.timeFinished}</span>
                        </td>
                      </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <Dialog open={showEditDialog} onClose={this.handleEditDialogClose.bind(this)}>
          <DialogTitle>Update User Score</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please fill the form to update user score.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="userid"
              label="User ID"
              type="text"
              variant="outlined"
              value={selectedParticipant ? selectedParticipant.user.id : null}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="fullname"
              label="Full Name"
              type="text"
              variant="outlined"
              value={selectedParticipant ? selectedParticipant.user.fullName : null}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="score"
              label="Score"
              type="number"
              variant="outlined"
              onChange={(event) => {
                const { selectedParticipant } = this.state;
                const value = parseInt(event.target.value);
                selectedParticipant.score = value;
                this.setState({
                  selectedParticipant
                });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleEditDialogClose.bind(this)}>Cancel</Button>
            <Button onClick={this.onEditDialogSave.bind(this)}>Save</Button>
          </DialogActions>
        </Dialog>

        {/* Invalid input score error */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar.bind(this)}
        >
          <SnackbarContentWrapper
            onClose={this.handleCloseSnackbar.bind(this)}
            variant={snackbarProperty.variant}
            message={snackbarProperty.message}
          />
        </Snackbar>
      </>
    );
  }
}

export default withStyles(styles)(UserCourseScoringPage);