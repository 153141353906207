import acsAxios from "./acsAxios";

export function getUsers(Filters = {}, Orders = {}, Offset, Limit) {
  return acsAxios.post('/mgmt/users', {
    Filters,
    Orders,
    ...(Offset ? { Offset } : {}),
    ...(Limit ? { Limit } : {})
  });
}

export function getTenants(Filters = {}, Orders = {}, Offset, Limit) {
  return acsAxios.post('/mgmt/tenants', {
    Filters,
    Orders,
    ...(Offset ? { Offset } : {}),
    ...(Limit ? { Limit } : {})
  });
}