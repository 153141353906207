import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Paper } from "@material-ui/core";
import { addLesson } from "../../../../redux/courseCrud";

export class CreateLessonDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newLesson: {}
    };
  }

  handleSave() {
    const { course } = this.props;
    const { onSave } = this.props;
    const { newLesson } = this.state;

    let isValid = true;
    if (newLesson.title === undefined) {
      isValid = false;
    }
    if (newLesson.orderNo === undefined) {
      isValid = false;
    }
    if (newLesson.platformType === undefined) {
      isValid = false;
    }
    if (newLesson.type === undefined) {
      isValid = false;
    }
    if (newLesson.duration === undefined) {
      isValid = false;
    }
    if (newLesson.content === undefined) {
      isValid = false;
    }

    if (isValid) {
      addLesson(course.id, newLesson)
        .then(({ data }) => {
          if (data.Success) {
            onSave();
            this.setState({
              newLesson: {}
            });
          }
        });
    }
  }

  render() {
    const { show } = this.props;
    const { onClose } = this.props;
    const { newLesson } = this.state;

    return (
      <Dialog open={show} onClose={onClose} maxWidth="lg">
        <DialogTitle>Create Lesson</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please change the field to edit lesson.
          </DialogContentText>
          <div className="m-4">
            <TextField
              id="new-lesson-title"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              value={newLesson.title}
              onChange={(e) => this.setState({ newLesson: { ...newLesson, title: e.target.value } })}
            />
            <TextField
              id="new-lesson-orderno"
              label="Order No"
              type="number"
              fullWidth
              variant="standard"
              value={newLesson.orderNo}
              onChange={(e) => this.setState({ newLesson: { ...newLesson, orderNo: e.target.value } })}
            />
            <FormControl
              fullWidth
            >
              <InputLabel>Platform Type</InputLabel>
              <Select
                id="new-lesson-platform-type"
                defaultValue={newLesson.platformType}
                onChange={(e) => this.setState({ newLesson: { ...newLesson, platformType: e.target.value } })}
              >
                <MenuItem value={"AIDA"}>AIDA</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              fullWidth
            >
              <InputLabel>Type</InputLabel>
              <Select
                id="new-lesson-type"
                defaultValue={newLesson.type}
                onChange={(e) => this.setState({ newLesson: { ...newLesson, type: e.target.value } })}
              >
                <MenuItem value={"vid"}>Video</MenuItem>
                <MenuItem value={"aud"}>Audio</MenuItem>
                <MenuItem value={"pdf"}>PDF</MenuItem>
              </Select>
            </FormControl>
            <TextField
              autoFocus
              id="new-lesson-duration"
              label="Duration (seconds)"
              type="number"
              fullWidth
              variant="standard"
              value={newLesson.duration}
              onChange={(e) => this.setState({ newLesson: { ...newLesson, duration: e.target.value } })}
            />
            <TextField
              autoFocus
              id="new-lesson-description"
              label="Description"
              type="text"
              fullWidth
              variant="standard"
              value={newLesson.description}
              onChange={(e) => this.setState({ newLesson: { ...newLesson, description: e.target.value } })}
            />
            <TextField
              autoFocus
              id="new-lesson-content"
              label="Content"
              type="text"
              fullWidth
              variant="standard"
              value={newLesson.content}
              onChange={(e) => this.setState({ newLesson: { ...newLesson, content: e.target.value } })}
            />
            <TextField
              autoFocus
              id="new-lesson-instruction"
              label="Instruction"
              type="text"
              fullWidth
              variant="standard"
              value={newLesson.instruction}
              onChange={(e) => this.setState({ newLesson: { ...newLesson, instruction: e.target.value } })}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={this.handleSave.bind(this)}>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}