import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Box, FormControlLabel, Snackbar, Input, Typography } from "@material-ui/core";
import AutoComplete from "@material-ui/lab/Autocomplete";
import { apiConfig } from "../../../config/api.config";
import { getCategories, getCourses, getParticipants, updateUserScore, uploadAidaSubmission } from "../../../redux/courseCrud";
import { toCamel } from "../../../utils";
import NumberFormat from "react-number-format";
import { withStyles } from "@material-ui/core";
import SnackbarContentWrapper from "../../components/SnackbarContentWrapper";
import { CloudUpload } from "@material-ui/icons";

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 2,
    marginRight: 2,
    width: 500,
  },
  centerBox: {
    padding: 10,
    height: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

class UploadCourseCorePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      order: { TimeEnrolled: "DESC", Score: "ASC" },
      offset: undefined,
      limit: undefined,
      localFilter: {},
      courses: [],
      selectedCourse: null,
      submissionResults: [],
      openSnackbar: false,
      snackbarProperty: {
        variant: "error",
        message: "Unhandled error. Please contact Administrator."
      }
    };
  }

  componentDidMount() {
    this.fetchCourses();
  }

  handleEditDialogClick() {
    this.setState({
      showEditDialog: true
    });
  }

  handleEditDialogClose() {
    this.setState({
      showEditDialog: false
    });
  }

  onEditDialogSave() {
    const { selectedParticipant } = this.state;
    if (selectedParticipant) {
      const score = selectedParticipant.score;

      if (!score || isNaN(score)) {
        this.setState({
          openSnackbar: true,
          snackbarProperty: {
            variant: "error",
            message: "Invalid score. Score cannot be empty and must a number."
          }
        });
      } else if (score < 0 || score > 5) {
        this.setState({
          openSnackbar: true,
          snackbarProperty: {
            variant: "error",
            message: "Invalid score. Score must between 0 - 5."
          }
        });
      } else {
        const { selectedCourse } = this.state;
        updateUserScore(selectedParticipant.user.id, selectedCourse.id, score)
          .then(({ data }) => {
            if (data.Success) {
              this.fetchParticipants();
              this.setState({
                openSnackbar: true,
                snackbarProperty: {
                  variant: "success",
                  message: "Score has been updated successfully."
                },
                showEditDialog: false
              });
            }
          });
      }
    }
  }

  handleCloseSnackbar() {
    this.setState({
      openSnackbar: false
    });
  }

  fetchCourses(filter = {}, order = {}, offset = undefined, limit = 10) {
    getCourses(filter, order, offset, limit)
      .then(({ data }) => {
        if (data.Success) {
          this.setState({
            courses: toCamel(data.Data)
          });
        }
      });
  }

  fetchParticipants() {
    const { localFilter, selectedCourse, filter, order, offset, limit } = this.state;

    if (selectedCourse) {
      getParticipants(selectedCourse.id, filter, order, offset, limit)
        .then(({ data }) => {
          if (data.Success) {
            if (localFilter.fullName) {
              const wrappedData = toCamel(data.Data);
              const filtered = wrappedData.filter(value => value.user.fullName.toLowerCase().includes(localFilter.fullName));
              this.setState({
                courseParticipants: filtered
              });
            } else {
              this.setState({
                courseParticipants: toCamel(data.Data)
              });
            }
          }
        })
    }
  }

  render() {
    const { classes } = this.props;
    const { openSnackbar, snackbarProperty } = this.state;
    const { courses, submissionResults } = this.state;

    return (
      <>
        <div className={`card card-custom `}>
          {/* Head */}
          <div className="card-header border-0 py-5">
            <div>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">Upload User Course Scores</span>
              </h3>
              <Box component="span" m={5}>
                <form style={{ display: "flex", flexWrap: "wrap" }}>
                  <FormControl>
                    <AutoComplete
                      options={courses}
                      getOptionLabel={(option) => option.title}
                      autoHighlight
                      renderInput={(params) =>
                        <TextField {...params}
                          className={classes.textField}
                          label="Select title"
                          margin="dense"
                          variant="outlined"
                          size="small"
                        />
                      }
                      onInputChange={(event) => {
                        const value = event.target.value;

                        const timeoutId = setTimeout(() => {
                          getCourses({ title: value }, {}, undefined, 10)
                            .then(({ data }) => {
                              if (data.Success) {
                                this.setState({
                                  courses: toCamel(data.Data)
                                });
                              }
                            });
                        }, 500);

                        return () => {
                          clearTimeout(timeoutId);
                        }
                      }}
                      onChange={(event, value) => {
                        this.setState({
                          selectedCourse: value
                        });
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <Box className={classes.centerBox}>
                      <input
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        className={classes.input}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(event) => {
                          const { selectedCourse } = this.state;
                          if (selectedCourse === null || selectedCourse === undefined) {
                            event.target.value = null; // enable change detection for re-upload
                            this.setState({
                              openSnackbar: true,
                              snackbarProperty: {
                                variant: "warning",
                                message: "Please select course first."
                              }
                            });
                            return;
                          }
                          
                          const file = event.target.files[0];
                          uploadAidaSubmission(selectedCourse.hash, file)
                            .then(({ data }) => {
                              if (data.validationErrors.length > 0) {
                                this.setState({
                                  submissionResults: data.submissionResults,
                                  openSnackbar: true,
                                  snackbarProperty: {
                                    variant: "warning",
                                    message: `Upload completed. But found some validation errors:${data.validationErrors.map(x => " " + x)}.`
                                  },
                                });
                              } else {
                                this.setState({
                                  submissionResults: data.submissionResults,
                                  openSnackbar: true,
                                  snackbarProperty: {
                                    variant: "success",
                                    message: "Upload completed without validation errors."
                                  },
                                });
                              }
                            })
                            .catch((error) => {
                              event.target.value = null; // enable change detection for re-upload
                              this.setState({
                                openSnackbar: true,
                                snackbarProperty: {
                                  variant: "error",
                                  message: error.response.data
                                }
                              });
                            });
                        }}
                      />
                      <label htmlFor="contained-button-file">
                        <Button className="btn btn-primary font-weight-bolder mt-2" variant="contained" component="span" onupl>
                          Upload
                          <CloudUpload className={classes.rightIcon}/>
                        </Button>
                      </label>
                    </Box>
                  </FormControl>
                </form>
              </Box>
            </div>
          </div>
          {/* Body */}
          <div className="card-body pt-0 pb-3">
            <div className="tab-content">
              <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      <th className="pl-7" style={{ minWidth: "40px" }}><span className="text-dark-75">No</span></th>
                      <th style={{ minWidth: "100px" }}>Email</th>
                      <th style={{ minWidth: "100px" }}>Score</th>
                      <th style={{ minWidth: "100px" }}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {submissionResults.map((submission, index) =>
                      <tr>
                        <td className="pl-0 py-8">
                          <div className="d-flex align-items-center">
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {index + 1}
                          </span>
                          </div>
                        </td>
                        <td className="pl-0 py-8">
                          <div className="d-flex align-items-center">
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {submission.email}
                          </span>
                          </div>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {submission.score}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {submission.status}
                          </span>
                        </td>
                      </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* Invalid input score error */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={openSnackbar}
          onClose={this.handleCloseSnackbar.bind(this)}
        >
          <SnackbarContentWrapper
            onClose={this.handleCloseSnackbar.bind(this)}
            variant={snackbarProperty.variant}
            message={snackbarProperty.message}
          />
        </Snackbar>
      </>
    );
  }
}

export default withStyles(styles)(UploadCourseCorePage);