/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useEffect, useState} from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import SVG from "react-inlinesvg";
import {useHtmlClassService} from "../../../_metronic/layout";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { getStatisticClassEnrollmentCount } from "../../../redux/courseCrud";
import { dateToString, periodicityText, toCamel } from "../../../utils";
import { max } from "lodash";
import { Button, makeStyles, TextField } from "@material-ui/core";
import { date } from "yup";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

export function CourseEnrollmentChart({ className, periodicity }) {
  const [init, setInit] = useState(true);
  const [fromDate, setFromDate] = useState(undefined);
  const [enrolledClassCount, setEnrolledClassCount] = useState([]);

  const uiService = useHtmlClassService();
  const classes = useStyles();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBasePrimary: objectPath.get(
        uiService.config,
        "js.colors.theme.base.warning"
      ),
      colorsThemeLightPrimary: objectPath.get(
        uiService.config,
        "js.colors.theme.light.warning"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  useEffect(() => {
    if (init) {
      const d = new Date();
      const defaultDate = new Date(d.getFullYear(), d.getMonth(), 1);
      const sDefaultDate = dateToString(defaultDate);
      setFromDate(sDefaultDate);
      fetchCourseEnrollmentCount(sDefaultDate);
      setInit(false);
    }

    const element = document.getElementById("chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps, enrolledClassCount);
    const chartEnrolledClasses = new ApexCharts(element, options);
    chartEnrolledClasses.render();
    return function cleanUp() {
      chartEnrolledClasses.destroy();
    };
  }, [layoutProps, enrolledClassCount]);

  const fetchCourseEnrollmentCount = (sDate) => {
    getStatisticClassEnrollmentCount(sDate, "d")
      .then(({ data }) => {
        if (data.Success) {
          setEnrolledClassCount(toCamel(data.Data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
          <div className="d-flex flex-column text-left">
            <span className="text-dark-75 font-weight-bolder font-size-h3">
              <TextField
                id="date"
                label="Start Date"
                type="date"
                defaultValue="2021-01-01"
                value={fromDate}
                // className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  setFromDate(e.target.value);
                }}
              />
              <Button variant="contained" color="secondary" className={classes.button} onClick={() => fetchCourseEnrollmentCount(fromDate)}>
                Apply
              </Button>
            </span>
            <span className="text-muted font-weight-bold mt-2"></span>
          </div>
          <div className="d-flex flex-column text-left">
            <span className="text-dark-75 font-weight-bolder font-size-h3">
              Course Enrollment
            </span>
          </div>
        </div>
        <div
          id="chart"
          className="card-rounded-bottom"
        ></div>
      </div>
    </div>
  );
}

function getChartOption(layoutProps, data) {
  var options = {
    series: [
      {
        name: "Counts",
        data: data.map(x => x.count)
      }
    ],
    chart: {
      type: "line",
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      },
      title: {
        text: 'Registered User',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      events: {
        click: function(event, chartContext, config) {
          console.log(event);
        }
      }
    },
    plotOptions: {},
    legend: {
      show: true
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "solid",
      opacity: 1
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBasePrimary]
    },
    xaxis: {
      categories: data.map(x => x.crd),
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: true
      },
      labels: {
        show: true,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      },
      crosshairs: {
        show: true,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3
        }
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    yaxis: {
      min: 0,
      max: max(data.map(x => x.count)) + 1,
      labels: {
        show: true,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function(val) {
          return "+" + val;
        }
      }
    },
    colors: [layoutProps.colorsThemeLightPrimary],
    markers: {
      colors: [layoutProps.colorsThemeLightPrimary],
      strokeColor: [layoutProps.colorsThemeBasePrimary],
      strokeWidth: 3
    }
  };
  return options;
}
