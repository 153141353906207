import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Paper } from "@material-ui/core";

export class EditCoursePriceDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editedCoursePrice: {}
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.coursePrice !== this.props.coursePrice) {
      this.setState({
        editedCoursePrice: this.props.coursePrice
      });
    }
  }

  render() {
    const { show } = this.props;
    const { onClose, onSave } = this.props;
    const { editedCoursePrice } = this.state;

    return (
      <Dialog open={show} onClose={onClose} maxWidth="lg">
        <DialogTitle>Edit Course Price</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please change the field to edit course price.
          </DialogContentText>
          <div className="m-4">
            <TextField
              id="edit-price-name"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              value={editedCoursePrice.name}
              onChange={(e) => this.setState({ editedCoursePrice: { ...editedCoursePrice, name: e.target.value } })}
            />
            <TextField
              id="edit-price-description"
              label="Description"
              type="text"
              fullWidth
              variant="standard"
              value={editedCoursePrice.description}
              onChange={(e) => this.setState({ editedCoursePrice: { ...editedCoursePrice, description: e.target.value } })}
            />
            <FormControl
              fullWidth
            >
              <InputLabel>TargetCountry</InputLabel>
              <Select
                id="edit-price-target-country"
                defaultValue={editedCoursePrice.targetCountry}
                onChange={(e) => this.setState({ editedCoursePrice: { ...editedCoursePrice, targetCountry: e.target.value } })}
              >
                <MenuItem value={"ID"}>Indonesia</MenuItem>
                <MenuItem value={"SG"}>Singapore</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="edit-price-from-time"
              label="From Time"
              type="datetime-local"
              fullWidth
              variant="standard"
              value={editedCoursePrice.fromTime}
              onChange={(e) => this.setState({ editedCoursePrice: { ...editedCoursePrice, fromTime: e.target.value } })}
            />
            <TextField
              id="edit-price-to-time"
              label="To Time"
              type="text"
              fullWidth
              variant="standard"
              value={editedCoursePrice.toTime}
              onChange={(e) => this.setState({ editedCoursePrice: { ...editedCoursePrice, toTime: e.target.value } })}
            />
            <TextField
              id="edit-price-currency"
              label="Currency"
              type="text"
              fullWidth
              variant="standard"
              value={editedCoursePrice.currency}
              onChange={(e) => this.setState({ editedCoursePrice: { ...editedCoursePrice, currency: e.target.value } })}
            />
            <TextField
              id="edit-price-value"
              label="Price"
              type="text"
              fullWidth
              variant="standard"
              value={editedCoursePrice.value}
              onChange={(e) => this.setState({ editedCoursePrice: { ...editedCoursePrice, value: e.target.value } })}
            />
            <TextField
              id="edit-price-basevalue"
              label="Price"
              type="text"
              fullWidth
              variant="standard"
              value={editedCoursePrice.baseValue}
              onChange={(e) => this.setState({ editedCoursePrice: { ...editedCoursePrice, value: e.target.baseValue } })}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSave}>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}