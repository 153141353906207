/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { apiConfig } from "../../../config/api.config";
import { getCourseMostAttended } from "../../../redux/courseCrud";
import { toCamel } from "../../../utils";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export function CourseMostAttendedWidget({ className }) {
  const [courses, setCourses] = useState([]);
  const [needUpdate, setNeedUpdate] = useState(true);

  useEffect(() => {
    if (needUpdate) {
      fetchCourseMostAttended();
    }
  }, [needUpdate]);

  const fetchCourseMostAttended = () => {
    getCourseMostAttended()
      .then(({ data }) => {
        if (data.Success) {
          setCourses(toCamel(data.Data));
          setNeedUpdate(false);
        }
      });
  }

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">Most Attended Course</span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">More than {courses.length > 0 ? courses.map(x => x.totalCount).reduce((prev, next) => prev + next) : 0}+ members attended</span>
        </h3>
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Title</span></th>
                  <th style={{ minWidth: "100px" }}>Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {courses.map(course =>
                  <tr>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                          <img alt={course.coverImageRID} src={`${apiConfig.apiUrl.course}/image/course/${course.coverImageRID}/thumb`} height={40}></img>
                          </span>
                        </div>
                        <div>
                          <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{course.title}</a>
                          <span className="text-muted font-weight-bold d-block">{course.topic} ({course.category})</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {course.totalCount}
                      </span>
                    </td>
                    <td className="pr-0 text-right">
                      <a href={`https://playbook.plus/courses/omc/h/${course.hash}`} className="btn btn-light-success font-weight-bolder font-size-sm">View</a>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
