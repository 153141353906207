import asplaAxios from "./asplaAxios";

export function getExams({ Filters = {}, Orders = {}, Offset, Limit }) {
  return asplaAxios.post('/data/exams', {
    Filters,
    Orders,
    ...(Offset ? { Offset } : {}),
    ...(Limit ? { Limit } : {})
  });
}

export function getExamType(id) {
  return asplaAxios.get(`/data/exam/type/${id}`);
}

export function editExamType(id, examType) {
  return asplaAxios.post(`/data/exam/type/${id}`, {
    ExamName: examType.examName,
    ExamTime: examType.examTime,
    Description: examType.description,
    Instruction: examType.instruction
  });
}