import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Grid } from "@material-ui/core";
import { apiConfig } from "../../../config/api.config";
import { getTenants } from "../../../redux/managementCrud";
import { toCamel } from "../../../utils";
import { editCategory, getCategories } from "../../../redux/courseCrud";

class CategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      filter: {},
      order: {},
      offset: undefined,
      limit: 10,
      showCreateDialog: false,
      showEditDialog: false,
      editedCategory: {},
    };
  }

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories() {
    getCategories()
      .then(({ data }) => {
        if (data.Success) {
          this.setState({
            categories: toCamel(data.Data)
          });
        }
      });
  }

  handleCreateDialogClick() {
    this.setState({
      showCreateDialog: true
    });
  }

  handleCreateDialogClose() {
    this.setState({
      showCreateDialog: false
    });
  }

  handleEditDialogClick(category) {
    this.setState({
      editedCategory: category,
      showEditDialog: true
    });
  }

  handleEditDialogClose() {
    this.setState({
      showEditDialog: false
    });
  }

  handleEditDialogSave() {
    const { editedCategory } = this.state;
    editCategory(editedCategory.id, editedCategory)
      .then(({ data }) => {
        if (data.Success) {
          this.fetchCategories();
          this.setState({
            showEditDialog: false
          });
        }
      });
  }

  render() {
    const { categories, showEditDialog } = this.state;
    const { editedCategory } = this.state;

    return (
      <>
        <div className={`card card-custom `}>
          {/* Head */}
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">Tenant</span>
            </h3>
          </div>
          {/* Body */}
          <div className="card-body pt-0 pb-3">
            <div className="tab-content">
              <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">ID</span></th>
                      <th style={{ minWidth: "100px" }}>Code</th>
                      <th style={{ minWidth: "100px" }}>Name</th>
                      <th style={{ minWidth: "100px" }}>ParentId</th>
                      <th style={{ minWidth: "100px" }}>Description</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map(category =>
                      <tr>
                        <td className="pl-0 py-8">
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {category.id}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {category.code}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {category.name}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {category.parentID}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {category.description}
                          </span>
                        </td>
                        <td className="pr-0 text-right">
                          <Button className="btn btn-light-success font-weight-bolder font-size-sm mr-2" onClick={() => this.handleEditDialogClick(category)}>Edit</Button>
                        </td>
                      </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <Dialog maxWidth="lg" open={showEditDialog} onClose={this.handleEditDialogClose.bind(this)}>
          <DialogTitle>Edit Course</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please change the field to edit course.
            </DialogContentText>
            <div className="m-4">
              <TextField
                id="edit-code"
                label="Code"
                type="text"
                fullWidth
                variant="standard"
                value={editedCategory.code}
                onChange={(e) => this.setState({ editedCategory: { ...editedCategory, code: e.target.value } })}
              />
              <TextField
                id="edit-name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                value={editedCategory.name}
                onChange={(e) => this.setState({ editedCategory: { ...editedCategory, name: e.target.value } })}
              />
              <TextField
                id="edit-name"
                label="ParentID"
                type="text"
                fullWidth
                variant="standard"
                value={editedCategory.parentID}
                onChange={(e) => this.setState({ editedCategory: { ...editedCategory, parentID: e.target.value } })}
              />
              <TextField
                id="edit-description"
                label="Description"
                type="text"
                fullWidth
                variant="standard"
                value={editedCategory.description}
                onChange={(e) => this.setState({ editedCategory: { ...editedCategory, description: e.target.value } })}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleEditDialogClose.bind(this)}>Cancel</Button>
            <Button onClick={this.handleEditDialogSave.bind(this)}>Save</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default CategoryPage;