import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Paper } from "@material-ui/core";
import { addCoursePrice, getCoursePrices, getLessons } from "../../../../redux/courseCrud";
import { toCamel } from "../../../../utils";
import { EditCoursePriceDialog } from "./EditCoursePriceDialog";
import { CreateCoursePriceDialog } from "./CreateCoursePriceDialog";

export class ViewCoursePricesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coursePrices: [],
      editedCoursePrice: {},
      showCreateCoursePriceDialog: false,
      showEditCoursePriceDialog: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.course.id !== this.props.course.id) {
      this.fetchCoursePrices();
    }
  }

  fetchCoursePrices() {
    const { course } = this.props;
    getCoursePrices(course.id)
      .then(({ data }) => {
        if (data.Success) {
          this.setState({
            coursePrices: toCamel(data.Data),
          });
        }
      });
  }

  handleCreateCoursePriceDialogClick() {
    this.setState({
      showCreateCoursePriceDialog: true
    });
  }
  
  handleCreateCoursePriceDialogClose() {
    this.setState({
      showCreateCoursePriceDialog: false
    });
  }

  handleCreateCoursePriceDialogSave(coursePrice) {
    this.setState({
      showCreateCoursePriceDialog: false
    });

    const { course } = this.props;
    coursePrice.courseId = course.id;
    addCoursePrice(coursePrice)
      .then(({ data }) => {
        if (data.Success) {
          this.fetchCoursePrices();
        }
      });
  }

  handleEditCoursePriceDialogClick(coursePrice) {
    this.setState({
      editedCoursePrice: coursePrice,
      showEditCoursePriceDialog: true
    });
  }
  
  handleEditCoursePriceDialogClose() {
    this.setState({
      showEditCoursePriceDialog: false
    });
  }

  handleEditCoursePriceDialogSave() {
    this.setState({
      showEditCoursePriceDialog: false
    });
  }

  render() {
    const { show } = this.props;
    const { onClose } = this.props;
    const { showCreateCoursePriceDialog, showEditCoursePriceDialog } = this.state;
    const { coursePrices, editedCoursePrice } = this.state;

    return (
      <>
        <Dialog open={show} onClose={onClose} maxWidth="lg">
          <DialogTitle>View Course Prices</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item lg={12}>
                <div className="m-2">
                  {/* Head */}
                  <div className="card-header border-0 py-5">
                    <div className="card-toolbar">
                      <button className="btn btn-info font-weight-bolder font-size-sm mr-3" onClick={this.handleCreateCoursePriceDialogClick.bind(this)}>Add Price</button>
                    </div>
                  </div>
                  {/* Body */}
                  <div className="card-body pt-0 pb-3">
                    <div className="tab-content">
                      <div className="table-responsive">
                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                          <thead>
                            <tr className="text-left text-uppercase">
                              <th className="pl-7" style={{ minWidth: "100px" }}><span className="text-dark-75">No</span></th>
                              <th style={{ minWidth: "100px" }}>Name<br />Description</th>
                              <th style={{ minWidth: "100px" }}>From<br />To</th>
                              <th style={{ minWidth: "100px" }}>Value<br />Base</th>
                              <th style={{ minWidth: "100px" }}>Target Country<br />Priority</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {coursePrices.map((cp, idx) =>
                              <tr>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {idx + 1}
                                  </span>
                                </td>
                                <td className="pl-0 py-8">
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{cp.name}</a>
                                      <span className="text-muted font-weight-bold d-block">{cp.description}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {cp.fromTime}
                                  </span>
                                  <span className="text-muted font-weight-bold d-block">{cp.toTime}</span>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {cp.currency} {cp.value}
                                  </span>
                                  <span className="text-muted font-weight-bold d-block">{cp.currency} {cp.baseValue}</span>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {cp.targetCountry}
                                  </span>
                                  <span className="text-muted font-weight-bold d-block">{cp.priority}</span>
                                </td>
                                <td className="pr-0 text-right">
                                  <Button className="btn btn-light-success font-weight-bolder font-size-sm mr-2" onClick={() => this.handleEditCoursePriceDialogClick(cp)}>Edit</Button>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <CreateCoursePriceDialog show={showCreateCoursePriceDialog} onClose={this.handleCreateCoursePriceDialogClose.bind(this)} onSave={this.handleCreateCoursePriceDialogSave.bind(this)} />
        <EditCoursePriceDialog coursePrice={editedCoursePrice} show={showEditCoursePriceDialog} onClose={this.handleEditCoursePriceDialogClose.bind(this)} onSave={this.handleEditCoursePriceDialogSave.bind(this)} />
      </>
    )
  }
}