import courseAxios from "./courseAxios";
import scAxios from "./scAxios";

// startDate format "yyyy-MM-dd"
// periodicity: "m" for month, "d" for day
export function getStatisticClassEnrollmentCount(startDate, periodicity) {
  return courseAxios.get(`/statistic/enrollment/count/${startDate}/${periodicity}`);
}

export function getTotalEnrollment() {
  return courseAxios.get('/statistic/enrollment/count/total');
}

export function getGrossRevenue(startDate, endDate) {
  if (startDate === undefined) {
    startDate = "2021-01-01";
  }
  if (endDate === undefined) {
    endDate = (new Date()).toISOString().split('T')[0];
  }
  return courseAxios.get(`/statistic/revenue/gross/${startDate}/to/${endDate}`);
}

export function getCourseMostAttended(startDate, endDate) {
  if (startDate === undefined) {
    startDate = "2021-01-01";
  }
  if (endDate === undefined) {
    endDate = (new Date()).toISOString().split('T')[0];
  }
  return courseAxios.get(`/statistic/course/mostattended/${startDate}/to/${endDate}`);
}

export function getCourseBestSeller(startDate, endDate) {
  if (startDate === undefined) {
    startDate = "2021-01-01";
  }
  if (endDate === undefined) {
    endDate = (new Date()).toISOString().split('T')[0];
  }
  return courseAxios.get(`/statistic/course/bestseller/${startDate}/to/${endDate}`)
}

export function getCourses(Filters = {}, Orders = {}, Offset, Limit) {
  return courseAxios.post('/data/courses', {
    Filters,
    Orders,
    ...(Offset ? { Offset } : {}),
    ...(Limit ? { Limit } : {})
  });
}

export function getLessons(courseId) {
  return courseAxios.get(`/data/course/${courseId}/lessons`);
}

export function getParticipants(courseId, Filters = {}, Orders = {}, Offset, Limit) {
  return courseAxios.post(`/course/${courseId}/participants`, {
    InjectUser: true,
    Filters,
    Orders,
    ...(Offset ? { Offset } : {}),
    ...(Limit ? { Limit } : {})
  });
}

export function getCategories() {
  return courseAxios.get('/data/categories');
}

export function editCategory(id, category) {
  return courseAxios.post(`/data/category/${id}`, {
    ParentID: category.parentID,
    Code: category.code,
    Name: category.name,
    Description: category.description
  });
}

export function getCompetencies() {
  return courseAxios.get('/data/competencies');
}

export function updateUserScore(userId, courseId, score) {
  return courseAxios.post(`/user/${userId}/course/${courseId}/score`, { Score: score });
}

export function uploadAidaSubmission(courseHash, excelFile) {
  const formData = new FormData();
  formData.append('file', excelFile);
  const config = {
    headers: {
      "content-type": "multipart/form-data"
    }
  }
  return scAxios.post(`/courses/legacy/aidasubmission/${courseHash}`, formData, config);
}

export function addCourse(course) {
  return courseAxios.post('/data/course', {
    InstitutionID: course.institutionID,
    TeacherID: course.teacherID,
    LeaderID: course.leaderID,
    CategoryID: course.categoryID,
    CompetencyID: course.competencyID,
    SeriesID: course.seriesID,
    Title: course.title,
    Type: course.type,
    TargetCountry: course.targetCountry,
    Language: course.language,
    Tier: course.tier,
    Target: course.target,
    Description: course.description,
    Goal: course.goal,
    Instruction: course.instruction,
    Slug: course.slug
  });
}

export function editCourse(id, course) {
  return courseAxios.post(`/data/course/${id}`, {
    InstitutionID: course.institutionID,
    TeacherID: course.teacherID,
    LeaderID: course.leaderID,
    CategoryID: course.categoryID,
    CompetencyID: course.competencyID,
    SeriesID: course.seriesID,
    Title: course.title,
    Type: course.type,
    TargetCountry: course.targetCountry,
    Language: course.language,
    Tier: course.tier,
    Target: course.target,
    Description: course.description,
    Goal: course.goal,
    Instruction: course.instruction,
    Slug: course.slug
  });
}

export function deleteCourse(id) {
  return courseAxios.delete(`/data/course/${id}`);
}

export function addLesson(courseId, lesson) {
  return courseAxios.post(`/data/course/${courseId}/lesson`, {
    Title: lesson.title,
    OrderNo: Number(lesson.orderNo),
    Type: lesson.type,
    PlatformType: lesson.platformType,
    Duration: Number(lesson.duration),
    Description: lesson.description,
    Content: lesson.content,
    Instruction: lesson.instruction
  });
}

export function editLesson(courseId, lessonId, lesson) {
  return courseAxios.post(`/data/course/${courseId}/lesson/${lessonId}`, {
    Hash: lesson.hash,
    Slug: lesson.slug,
    Title: lesson.title,
    OrderNo: Number(lesson.orderNo),
    Type: lesson.type,
    PlatformType: lesson.platformType,
    Duration: Number(lesson.duration),
    Description: lesson.description,
    Content: lesson.content,
    Instruction: lesson.instruction
  });
}

export function getCoursePrices(courseId) {
  return courseAxios.get(`/data/course/${courseId}/prices`);
}

export function addCoursePrice(coursePrice) {
  return courseAxios.post(`/data/course/${coursePrice.courseId}/price`, {
      CourseID: coursePrice.courseId,
      TargetCountry: coursePrice.targetCountry,
      FromTime: coursePrice.fromTime,
      Currency: coursePrice.currency,
      Priority: coursePrice.priority,
      Value: coursePrice.value,
      BaseValue: coursePrice.baseValue,
      Name: coursePrice.name,
      Description: coursePrice.description
    });
}

export function getCoursePrice(courseId, coursePriceId) {
  return courseAxios.get(`​/data​/course​/${courseId}​/price​/${coursePriceId}`);
}

export function editCoursePrice(courseId, coursePriceId, coursePrice) {
  return courseAxios.post(`/data/course/${courseId}/price/${coursePriceId}`, {
    CourseID: coursePrice.courseId,
    TargetCountry: coursePrice.targetCountry,
    FromTime: coursePrice.fromTime,
    Currency: coursePrice.currency,
    Priority: coursePrice.priority,
    Value: coursePrice.value,
    Name: coursePrice.name,
    Description: coursePrice.description
  });
}

export function getCourseImages(courseId) {
  return courseAxios.get(`/data/course/${courseId}/images`);
}

export function uploadCourseCoverImage(courseId, image) {
  const formData = new FormData();
  formData.append('ImageData', image)
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  return courseAxios.post(`/data/course/${courseId}/image/cover`, formData, config);
}

export function uploadCourseLandscapeImage(courseId, image) {
  const formData = new FormData();
  formData.append('ImageData', image)
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  return courseAxios.post(`/data/course/${courseId}/image/landscape`, formData, config);
}