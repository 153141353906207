import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Paper } from "@material-ui/core";

export class CreateCoursePriceDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newCoursePrice: {}
    };
  }

  render() {
    const { show } = this.props;
    const { onClose, onSave } = this.props;
    const { newCoursePrice } = this.state;

    return (
      <Dialog open={show} onClose={onClose} maxWidth="lg">
        <DialogTitle>Create Course Price</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill the field to create course price.
          </DialogContentText>
          <div className="m-4">
            <TextField
              id="edit-price-name"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              value={newCoursePrice.name}
              onChange={(e) => this.setState({ newCoursePrice: { ...newCoursePrice, name: e.target.value } })}
            />
            <TextField
              id="edit-price-description"
              label="Description"
              type="text"
              fullWidth
              variant="standard"
              value={newCoursePrice.description}
              onChange={(e) => this.setState({ newCoursePrice: { ...newCoursePrice, description: e.target.value } })}
            />
            <FormControl
              fullWidth
            >
              <InputLabel>TargetCountry</InputLabel>
              <Select
                id="edit-price-target-country"
                defaultValue={newCoursePrice.targetCountry}
                onChange={(e) => this.setState({ newCoursePrice: { ...newCoursePrice, targetCountry: e.target.value } })}
              >
                <MenuItem value={"ID"}>Indonesia</MenuItem>
                <MenuItem value={"SG"}>Singapore</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="edit-price-from-time"
              label="From Time"
              type="datetime-local"
              fullWidth
              variant="standard"
              value={newCoursePrice.fromTime}
              onChange={(e) => this.setState({ newCoursePrice: { ...newCoursePrice, fromTime: e.target.value } })}
            />
            <TextField
              id="edit-price-to-time"
              label="To Time"
              type="text"
              fullWidth
              variant="standard"
              value={newCoursePrice.toTime}
              onChange={(e) => this.setState({ newCoursePrice: { ...newCoursePrice, toTime: e.target.value } })}
            />
            <TextField
              id="edit-price-priority"
              label="Priority"
              type="text"
              fullWidth
              variant="standard"
              value={newCoursePrice.priority}
              onChange={(e) => this.setState({ newCoursePrice: { ...newCoursePrice, priority: e.target.value } })}
            />
            <TextField
              id="edit-price-currency"
              label="Currency"
              type="text"
              fullWidth
              variant="standard"
              value={newCoursePrice.currency}
              onChange={(e) => this.setState({ newCoursePrice: { ...newCoursePrice, currency: e.target.value } })}
            />
            <TextField
              id="edit-price-value"
              label="Price"
              type="text"
              fullWidth
              variant="standard"
              value={newCoursePrice.value}
              onChange={(e) => this.setState({ newCoursePrice: { ...newCoursePrice, value: e.target.value } })}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={() => onSave(newCoursePrice)}>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}