import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Paper } from "@material-ui/core";
import { editLesson } from "../../../../redux/courseCrud";

export class EditLessonDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editedLesson: {}
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lesson !== this.props.lesson) {
      this.setState({
        editedLesson: this.props.lesson
      });
    }
  }

  handleSave() {
    const { onSave } = this.props;
    const { editedLesson } = this.state;
    editLesson(editedLesson.courseID, editedLesson.id, editedLesson)
      .then(({ data }) => {
        if (data.Success) {
          this.setState({
            editedLesson: {}
          });
          onSave();
        }
      });
  }

  render() {
    const { show } = this.props;
    const { onClose } = this.props;
    const { editedLesson } = this.state;

    return (
      <Dialog open={show} onClose={onClose} maxWidth="lg">
        <DialogTitle>Edit Lesson</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please change the field to edit lesson.
          </DialogContentText>
          <div className="m-4">
            <TextField
              id="edit-lesson-title"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              value={editedLesson.title}
              onChange={(e) => this.setState({ editedLesson: { ...editedLesson, title: e.target.value } })}
            />
            <TextField
              id="edit-lesson-orderno"
              label="Order No"
              type="number"
              fullWidth
              variant="standard"
              value={editedLesson.orderNo}
              onChange={(e) => this.setState({ editedLesson: { ...editedLesson, orderNo: e.target.value } })}
            />
            <FormControl
              fullWidth
            >
              <InputLabel>Platform Type</InputLabel>
              <Select
                id="edit-lesson-platform-type"
                defaultValue={editedLesson.platformType}
                onChange={(e) => this.setState({ editedLesson: { ...editedLesson, platformType: e.target.value } })}
              >
                <MenuItem value={"AIDA"}>AIDA</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              fullWidth
            >
              <InputLabel>Type</InputLabel>
              <Select
                id="edit-lesson-type"
                defaultValue={editedLesson.type}
                onChange={(e) => this.setState({ editedLesson: { ...editedLesson, type: e.target.value } })}
              >
                <MenuItem value={"vid"}>Video</MenuItem>
                <MenuItem value={"aud"}>Audio</MenuItem>
                <MenuItem value={"pdf"}>PDF</MenuItem>
              </Select>
            </FormControl>
            <TextField
              autoFocus
              id="edit-lesson-duration"
              label="Duration (seconds)"
              type="number"
              fullWidth
              variant="standard"
              value={editedLesson.duration}
              onChange={(e) => this.setState({ editedLesson: { ...editedLesson, duration: e.target.value } })}
            />
            <TextField
              autoFocus
              id="edit-lesson-description"
              label="Description"
              type="text"
              fullWidth
              variant="standard"
              value={editedLesson.description}
              onChange={(e) => this.setState({ editedLesson: { ...editedLesson, description: e.target.value } })}
            />
            <TextField
              autoFocus
              id="edit-lesson-content"
              label="Content"
              type="text"
              fullWidth
              variant="standard"
              value={editedLesson.content}
              onChange={(e) => this.setState({ editedLesson: { ...editedLesson, content: e.target.value } })}
            />
            <TextField
              autoFocus
              id="edit-lesson-instruction"
              label="Instruction"
              type="text"
              fullWidth
              variant="standard"
              value={editedLesson.instruction}
              onChange={(e) => this.setState({ editedLesson: { ...editedLesson, instruction: e.target.value } })}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={this.handleSave.bind(this)}>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}