import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Paper, Box } from "@material-ui/core";
import { uploadCourseCoverImage, uploadCourseLandscapeImage } from "../../../../redux/courseCrud";
import { toCamel } from "../../../../utils";
import { apiConfig } from "../../../../config/api.config";

export class CourseImagesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleUploadCoverImageClick(e) {
    if (e.target.files.length > 0) {
      const { course } = this.props;
      const file = e.target.files[0];
      uploadCourseCoverImage(course.id, file)
        .then(({ data }) => {
          if (data.Success) {
          }
        });
    }
  }

  handleUploadLandscapeImageClick(e) {
    if (e.target.files.length > 0) {
      const { course } = this.props;
      const file = e.target.files[0];
      uploadCourseLandscapeImage(course.id, file);
    }
  }

  render() {
    const { show } = this.props;
    const { onClose } = this.props;
    const { course } = this.props;

    return (
      <Dialog open={show} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle>Course Images</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="m-2">
                {/* Head */}
                <div className="card-header border-0 py-5">
                  <div className="card-toolbar">
                    <Box display="flex" justifyContent="center">
                      <Button
                        className="btn btn-info font-weight-bolder font-size-sm mr-3"
                        variant="contained"
                        component="label"
                      >
                        Upload Image (Cover)
                        <input id="cover-image-upload" type="file" accept="image/*" hidden onChange={this.handleUploadCoverImageClick.bind(this)} />
                      </Button>
                    </Box>
                  </div>
                </div>
                {/* Body */}
                <div className="card-body pt-0 pb-3">
                  {course && course.coverImageRID ?
                    <div class="container" style={{ width: 400 }}>
                      <img class="image" alt={course.coverImageRID} hidden={course.coverImageRID === undefined} src={`${apiConfig.apiUrl.course}/image/course/${course.coverImageRID}/standard`} />
                    </div>
                    : (null)
                  }
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {/* Head */}
              <div className="card-header border-0 py-5">
                <div className="card-toolbar">
                  <Box display="flex" justifyContent="center">
                    <Button
                      className="btn btn-info font-weight-bolder font-size-sm mr-3"
                      variant="contained"
                      component="label"
                    >
                      Upload Image (Landscape)
                      <input id="landscape-image-upload" type="file" accept="image/*" hidden onChange={this.handleUploadLandscapeImageClick.bind(this)} />
                    </Button>
                  </Box>
                </div>
              </div>
              {/* Body */}
              <div className="card-body pt-0 pb-3">
                {course && course.landscapeImageRID ?
                  <div class="container" style={{ width: 400 }}>
                    <img class="image" alt={course.landscapeImageRID} hidden={course.landscapeImageRID === undefined} src={`${apiConfig.apiUrl.course}/image/course/${course.landscapeImageRID}/standard`} />
                  </div>
                  : (null)
                }
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}