import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { apiConfig } from "../../../config/api.config";
import { getTenants } from "../../../redux/managementCrud";
import { toCamel } from "../../../utils";
import NumberFormat from "react-number-format";

class TenantPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenants: [],
      filter: {},
      order: {},
      offset: undefined,
      limit: 10,
      showCreateDialog: false,
      showEditDialog: false
    };
  }

  componentDidMount() {
    const { filter, order, offset, limit } = this.state;
    getTenants(filter, order, offset, limit)
      .then(({ data }) => {
        if (data.Success) {
          this.setState({
            tenants: toCamel(data.Data)
          });
        }
      });
  }

  handleCreateDialogClick() {
    this.setState({
      showCreateDialog: true
    });
  }

  handleCreateDialogClose() {
    this.setState({
      showCreateDialog: false
    });
  }

  handleEditDialogClick() {
    this.setState({
      showEditDialog: true
    });
  }

  hanldeEditDialogClose() {
    this.setState({
      showEditDialog: false
    });
  }

  render() {
    const { tenants, showCreateDialog, showEditDialog } = this.state;

    return (
      <>
        <div className={`card card-custom `}>
          {/* Head */}
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">Tenant</span>
            </h3>
          </div>
          {/* Body */}
          <div className="card-body pt-0 pb-3">
            <div className="tab-content">
              <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Name</span></th>
                      <th style={{ minWidth: "100px" }}>E-Mail Address</th>
                      <th style={{ minWidth: "100px" }}>Description</th>
                      <th style={{ minWidth: "100px" }}>Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tenants.map(tenant =>
                      <tr>
                        <td className="pl-0 py-8">
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-50 symbol-light mr-4">
                              <span className="symbol-label">
                                <img alt={tenant.activeRID} src={`${apiConfig.apiUrl.acs}/picture/${tenant.activeRID}/thumb`} height={40}></img>
                              </span>
                            </div>
                            <div>
                              <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{tenant.name}</a>
                              <span className="text-muted font-weight-bold d-block">{tenant.hashCode}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {tenant.eMailAddress}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {tenant.description}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {tenant.sFlag}
                          </span>
                        </td>
                      </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <Dialog open={showCreateDialog} onClose={this.handleCreateDialogClose.bind(this)}>
          <DialogTitle>Create Company</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please fill the form to add company.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCreateDialogClose.bind(this)}>Cancel</Button>
            <Button onClick={this.handleCreateDialogClose.bind(this)}>Save</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default TenantPage;