import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Paper } from "@material-ui/core";
import { addLesson, getLessons } from "../../../../redux/courseCrud";
import { toCamel } from "../../../../utils";
import { CreateLessonDialog } from "./CreateLessonDialog";
import { EditLessonDialog } from "./EditLessonDialog";

export class ViewLessonsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateLessonDialog: false,
      showEditLessonDialog: false,
      lessons: [],
      editedLesson: {}
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.course.id !== this.props.course.id) {
      this.fetchLessons();
    }
  }

  fetchLessons() {
    const { course } = this.props;
    getLessons(course.id)
      .then(({ data }) => {
        if (data.Success) {
          this.setState({
            lessons: toCamel(data.Data)
          });
        }
      });
  }

  handleCreateLessonDialogClick() {
    this.setState({
      showCreateLessonDialog: true
    });
  }

  handleCreateLessonDialogClose() {
    this.setState({
      showCreateLessonDialog: false
    });
  }

  handleCreateLessonDialogSave() {
    this.fetchLessons();
    this.setState({
      showCreateLessonDialog: false
    });
  }

  handleEditLessonDialogClick(lesson) {
    this.setState({
      editedLesson: lesson,
      showEditLessonDialog: true
    });
  }

  handleEditLessonDialogClose() {
    this.setState({
      showEditLessonDialog: false
    });
  }

  handleEditLessonDialogSave() {
    this.fetchLessons();
    this.setState({
      showEditLessonDialog: false
    });
  }

  render() {
    const { show } = this.props;
    const { onClose } = this.props;
    const { course } = this.props;
    const { showCreateLessonDialog, showEditLessonDialog } = this.state;
    const { lessons, editedLesson } = this.state;

    return (
      <>
        <Dialog open={show} onClose={onClose} maxWidth="lg">
          <DialogTitle>Lessons</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item lg={12}>
                <div className="m-2">
                  {/* Head */}
                  <div className="card-header border-0 py-5">
                    <div className="card-toolbar">
                      <button className="btn btn-info font-weight-bolder font-size-sm mr-3" onClick={this.handleCreateLessonDialogClick.bind(this)}>Add Lesson</button>
                    </div>
                  </div>
                  {/* Body */}
                  <div className="card-body pt-0 pb-3">
                    <div className="tab-content">
                      <div className="table-responsive">
                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                          <thead>
                            <tr className="text-left text-uppercase">
                              <th style={{ minWidth: "100px" }}>No</th>
                              <th className="pl-7" style={{ minWidth: "100px" }}><span className="text-dark-75">Title</span></th>
                              <th style={{ minWidth: "100px" }}>Duration (mins)<br />Type</th>
                              <th style={{ minWidth: "100px" }}>Platform</th>
                              <th style={{ minWidth: "100px" }}>Description</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {lessons ? lessons.map(lesson =>
                              <tr>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {lesson.orderNo}
                                  </span>
                                </td>
                                <td className="pl-0 py-8">
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{lesson.title}</a>
                                      <span className="text-muted font-weight-bold d-block">{lesson.hash}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {lesson.duration}
                                  </span>
                                  <span className="text-muted font-weight-bold d-block">{lesson.type}</span>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {lesson.platformType}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {lesson.description}
                                  </span>
                                </td>
                                <td className="pr-0 text-right">
                                  <Button className="btn btn-light-success font-weight-bolder font-size-sm mr-2" onClick={() => this.handleEditLessonDialogClick(lesson)}>Edit</Button>
                                </td>
                              </tr>
                            ) : (null)
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </Dialog>

        <CreateLessonDialog course={course} show={showCreateLessonDialog} onClose={this.handleCreateLessonDialogClose.bind(this)} onSave={this.handleCreateLessonDialogSave.bind(this)} />
        <EditLessonDialog lesson={editedLesson} show={showEditLessonDialog} onClose={this.handleEditLessonDialogClose.bind(this)} onSave={this.handleEditLessonDialogSave.bind(this)} />
      </>
    )
  }
}