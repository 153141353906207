import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Paper } from "@material-ui/core";
import { editExamType } from "../../../../redux/examCrud";

export class EditExamTypeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editedExamType: {}
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.examType !== this.props.examType) {
      this.setState({
        editedExamType: this.props.examType
      });
    }
  }

  handleSave() {
    const { onSave } = this.props;
    const { editedExamType } = this.state;
    editExamType(editedExamType.id, editedExamType)
      .then(({ data }) => {
        if (data.Success) {
          this.setState({
            editedExamType: {}
          });
          onSave();
        }
      });
  }

  render() {
    const { show } = this.props;
    const { onClose } = this.props;
    const { editedExamType } = this.state;

    return (
      <Dialog open={show} onClose={onClose} maxWidth="lg">
        <DialogTitle>Edit Exam Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please change the field to edit exam type.
          </DialogContentText>
          <div className="m-4">
            <TextField
              id="edit-examtype-examname"
              label="Exam Name"
              type="text"
              fullWidth
              variant="standard"
              value={editedExamType.examName}
              onChange={(e) => this.setState({ editedExamType: { ...editedExamType, examName: e.target.value } })}
            />
            <TextField
              autoFocus
              id="edit-examtype-examtime"
              label="Exam Time"
              type="number"
              fullWidth
              variant="standard"
              value={editedExamType.examTime}
              onChange={(e) => this.setState({ editedExamType: { ...editedExamType, examTime: e.target.value } })}
            />
            <TextField
              autoFocus
              id="edit-examtype-description"
              label="Description"
              type="text"
              fullWidth
              variant="standard"
              value={editedExamType.description}
              onChange={(e) => this.setState({ editedExamType: { ...editedExamType, description: e.target.value } })}
            />
            <TextField
              autoFocus
              id="edit-examtype-instruction"
              label="Instruction"
              type="text"
              fullWidth
              variant="standard"
              value={editedExamType.instruction}
              onChange={(e) => this.setState({ editedExamType: { ...editedExamType, instruction: e.target.value } })}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={this.handleSave.bind(this)}>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}