import scAxios from "./scAxios";
import acsAxios from "./acsAxios";

export function getAcsUserProfileByToken() {
  return acsAxios.get("/self/profile");
}

export function exchangeTokenToPresence(token) {
  return scAxios.get(`/users/auth/exchange/${token}`);
}

export function logout() {
  return scAxios.get('/users/auth/logout');
}

export function validatePresence() {
  return acsAxios.get(`/presence/validate`);
}