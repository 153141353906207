import React, { useState } from "react";
import { useSubheader } from "../../_metronic/layout";
import Chart from "react-apexcharts";

export const MyPage = () => {
  const [options, setOptions] = useState({
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
    },
    stroke: {
      curve: "smooth",
    }
  });

  const [series, setSeries] = useState([
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91]
    }
  ]);

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-xxl-12">
          <Chart
            options={options}
            series={series}
            type="line"
            height="480"
          >
          </Chart>
        </div>
      </div>
    </>
  );
};
