/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import NumberFormat from "react-number-format";
import { getTotalEnrollment } from "../../../redux/courseCrud";
import { toCamel } from "../../../utils";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

export function EnrolledClassCountWidget({
  className,
  iconColor = "success",
  widgetHeight = "150px",
}) {
  const [totalEnrollment, setTotalEnrollment] = useState(0);

  useEffect(() => {
    getTotalEnrollment()
      .then(({ data }) => {
        if (data.Success) {
          const payload = toCamel(data.Counts);
          setTotalEnrollment(payload.total);
        }
      });
  });

  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className="card-body">
          <span className={`svg-icon svg-icon-3x svg-icon-${iconColor}`}>
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
            />
          </span>
          <div className="text-dark font-weight-bolder font-size-h2 mt-3">
            <NumberFormat
              value={totalEnrollment}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
            />
          </div>
          <a
            href="#"
            className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
          >
            Total Enrollment
          </a>
        </div>
      </div>
    </>
  );
}
