import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { apiConfig } from "../../../../config/api.config";
import { exchangeTokenToPresence, validatePresence } from "../../../../redux/authCrud";
import * as auth from "../../../../redux/authRedux";

const qs = require('qs');

function AuthCallbackPage(props) {
  const token = qs.parse(props.history.location.search, { ignoreQueryPrefix: true }).token;
  if (token !== null || token !== undefined) {
    exchangeTokenToPresence(token)
      .then((presence) => {
        const now = new Date();
        if (presence.data !== '') {
          props.login(presence.data, new Date(now.getTime() + 2592000000));
        }
        validatePresence()
          .then(({ data }) => {
            if (data.Success) {
              if (data.Permissions.includes('STDADM')) {
                props.history.push("/");
              } else {
                props.history.push("/logout");
              }
            } else {
              props.history.push("/logout");
            }
          });
      })
      .catch(() => {
        return (<Redirect to={`${apiConfig.apiUrl.skillcard}/users/auth`} />);
      });
  } else {
    return (<Redirect to={`${apiConfig.apiUrl.skillcard}/users/auth`} />);
  }
  
  const redirectUrl = qs.parse(props.history.location.search, { ignoreQueryPrefix: true }).redirectUrl;
  if (redirectUrl) {
    return (<Redirect to={redirectUrl} />);
  } else {
    return (<></>);
  }
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(AuthCallbackPage)
);
