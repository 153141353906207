/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import NumberFormat from "react-number-format";
import { getTotalUserTraining } from "../../../redux/userCrud";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

export function UserTrainingStatsWidget({
  className,
  iconColor = "success",
  widgetHeight = "150px",
}) {
  const [totalUser, setTotalUser] = useState(0);

  useEffect(() => {
    getTotalUserTraining()
      .then(({ data }) => {
        if (data) {
          setTotalUser(data);
        }
      })
      .catch((e) => console.log(e));
  });

  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className="card-body">
          <span className={`svg-icon svg-icon-3x svg-icon-${iconColor}`}>
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
            />
          </span>
          <div className="text-dark font-weight-bolder font-size-h2 mt-3">
            <NumberFormat
              value={totalUser}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
            />
          </div>
          <a
            href="#"
            className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
          >
            Total Users (Use Case #1)
          </a>
        </div>
      </div>
    </>
  );
}
