import acsAxios from "./acsAxios";
import scAxios from "./scAxios";

// startDate format "yyyy-MM-dd"
// periodicity: "m" for month, "d" for day
export function getStatisticRegisteredUserCount(startDate, periodicity) {
  return acsAxios.get(`/mgmt/stat/counter/user/registration/${startDate}/${periodicity}`);
}

// periodicity: "d" for day, "h" for hour
export function getStatisticActiveUserCount(periodicity) {
  return acsAxios.get(`/mgmt/stat/counter/user/active/${periodicity}`);
}

export function getTotalRegisteredUserCount() {
  return acsAxios.get('/mgmt/stat/counter/user/registration/total');
}

export function getTotalUserTraining() {
  return scAxios.get('/statistics/user/training/total');
}