/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import NumberFormat from "react-number-format";
import { getGrossRevenue } from "../../../redux/courseCrud";
import { toCamel } from "../../../utils";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export function GrossRevenueWidget({
  className,
  iconColor = "success",
  widgetHeight = "150px",
}) {
  const [grossRevenue, setGrossRevenue] = useState([]);

  useEffect(() => {
    getGrossRevenue()
      .then(({ data }) => {
        if (data.Success) {
          setGrossRevenue(toCamel(data.Data));
        }
      });
  }, []);

  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight }}
      >

        <div className="card-body">
          <span className={`svg-icon svg-icon-3x svg-icon-${iconColor}`}>
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")}
            />
          </span>
          {grossRevenue.map(gr =>
            <div className="text-dark font-weight-bolder font-size-h2 mt-3">
              <NumberFormat
                value={gr.totalAmount}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                prefix={gr.currency}
              />
            </div>
          )}
          <a
            href="#"
            className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
          >
            Gross Revenue
          </a>
        </div>
      </div>
    </>
  );
}
