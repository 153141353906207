import React from "react";
import {
  ActiveUserStatsWidget,
  EnrolledClassStatsWidget,
  RegisteredUserStatsWidget,
  RegisteredUserCountWidget,
  EnrolledClassCountWidget,
  CourseBestSellerWidget,
  CourseMostAttendedWidget,
  CourseLeastAttendedWidget,
  GrossRevenueWidget,
  UserTrainingStatsWidget,
} from "./widgets";

export function DashboardPage() {
  return (<>
    <div className="row">
      <div className="col-lg-6 col-xxl-4">
        <RegisteredUserCountWidget className="card-stretch card-stretch-half gutter-b" />
      </div>
      <div className="col-lg-6 col-xxl-4">
        <EnrolledClassCountWidget className="card-stretch card-stretch-half gutter-b" />
      </div>
      <div className="col-lg-6 col-xxl-4">
        <GrossRevenueWidget className="card-stretch card-stretch-half gutter-b" />  
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6 col-xxl-4">
        <ActiveUserStatsWidget elementId="kt_stats_widget_active_user_monthly_chart" className="card-sreatch card-stretch-half gutter-b" periodicity="d" />
        <ActiveUserStatsWidget elementId="kt_stats_widget_active_user_daily_chart" className="card-sreatch card-stretch-half gutter-b" periodicity="h" />
      </div>
      <div className="col-lg-6 col-xxl-4">
        <RegisteredUserStatsWidget elementId="kt_stats_widget_registered_user_monthly_chart" className="card-stretch card-stretch-half gutter-b" periodicity="m" />
        <RegisteredUserStatsWidget elementId="kt_stats_widget_registered_user_daily_chart" className="card-stretch card-stretch-half gutter-b" periodicity="d" />
      </div>
      <div className="col-lg-6 col-xxl-4">
        <EnrolledClassStatsWidget elementId="kt_stats_widget_enrolled_class_monthly_chart" className="card-sreatch card-stretch-half gutter-b" periodicity="m" />
        <EnrolledClassStatsWidget elementId="kt_stats_widget_enrolled_class_daily_chart" className="card-sreatch card-stretch-half gutter-b" periodicity="d" />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6 col-xxl-4">
        <UserTrainingStatsWidget className="card-stretch card-stretch-half gutter-b" />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <CourseMostAttendedWidget className="card-stretch gutter-b" />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <CourseLeastAttendedWidget className="card-stretch gutter-b" />
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <CourseBestSellerWidget className="card-stretch gutter-b" />
      </div>
    </div>
  </>)
}
