import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Paper } from "@material-ui/core";

export class EditCourseDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editedCourse: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.course !== this.props.course) {
      this.setState({
        editedCourse: this.props.course
      });
    }
  }

  render() {
    const { show, categories, competencies } = this.props;
    const { onClose, onSave } = this.props;
    const { editedCourse } = this.state;

    return (
      <Dialog open={show} onClose={onClose} maxWidth="lg">
        <DialogTitle>Edit Course</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please change the field to edit course.
          </DialogContentText>
          <Grid container>
            <Grid item lg={12}>
              <div className="m-4">
                <TextField
                  id="edit-title"
                  label="Title"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={editedCourse.title}
                  onChange={(e) => this.setState({ editedCourse: { ...editedCourse, title: e.target.value } })}
                />
                <TextField
                  id="edit-slug"
                  label="Slug"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={editedCourse.slug}
                  onChange={(e) => this.setState({ editedCourse: { ...editedCourse, slug: e.target.value } })}
                />
                <FormControl
                  fullWidth
                >
                  <InputLabel>Type</InputLabel>
                  <Select
                    id="edit-type"
                    defaultValue={editedCourse.type}
                    onChange={(e) => this.setState({ editedCourse: { ...editedCourse, type: e.target.value } })}
                  >
                    <MenuItem value={"F"}>Kelas Mikro Gratis</MenuItem>
                    <MenuItem value={"P"}>Kelas Mikro Plus</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                >
                  <InputLabel>Category</InputLabel>
                  <Select
                    id="edit-category"
                    defaultValue={editedCourse.categoryID}
                    onChange={(e) => this.setState({ editedCourse: { ...editedCourse, categoryID: e.target.value } })}
                  >
                    {categories.map(c => <MenuItem value={c.id}>{c.name}</MenuItem>)}
                  </Select>
                </FormControl>
                <FormControl
            fullWidth
          >
            <InputLabel>Competency</InputLabel>
            <Select
              id="edit-competency"
              defaultValue={editedCourse.competencyID}
              onChange={(e) => this.setState({ editedCourse: { ...editedCourse, competencyID: e.target.value } })}
            >
              {competencies.map(c => <MenuItem value={c.id}>{c.name}</MenuItem>)}
            </Select>
          </FormControl>
                <FormControl
                  fullWidth
                >
                  <InputLabel>TargetCountry</InputLabel>
                  <Select
                    id="edit-targetcountry"
                    defaultValue={editedCourse.targetCountry}
                    onChange={(e) => this.setState({ editedCourse: { ...editedCourse, targetCountry: e.target.value } })}
                  >
                    <MenuItem value={"ID"}>Indonesia</MenuItem>
                    <MenuItem value={"SG"}>Singapore</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                >
                  <InputLabel>Language</InputLabel>
                  <Select
                    id="edit-language"
                    defaultValue={editedCourse.language}
                    onChange={(e) => this.setState({ editedCourse: { ...editedCourse, language: e.target.value } })}
                  >
                    <MenuItem value={"id"}>Bahasa</MenuItem>
                    <MenuItem value={"en"}>English</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                >
                  <InputLabel>Tier</InputLabel>
                  <Select
                    id="edit-tier"
                    defaultValue={editedCourse.tier}
                    onChange={(e) => this.setState({ editedCourse: { ...editedCourse, tier: e.target.value } })}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                >
                  <InputLabel>Target</InputLabel>
                  <Select
                    id="edit-target"
                    defaultValue={editedCourse.target}
                    onChange={(e) => this.setState({ editedCourse: { ...editedCourse, target: e.target.value } })}
                  >
                    <MenuItem value={"PRO"}>PRO</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  autoFocus
                  id="description"
                  label="Description"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={editedCourse.description}
                  onChange={(e) => this.setState({ editedCourse: { ...editedCourse, description: e.target.value } })}
                />
                <TextField
                  autoFocus
                  id="instruction"
                  label="Instruction"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={editedCourse.instruction}
                  onChange={(e) => this.setState({ editedCourse: { ...editedCourse, instruction: e.target.value } })}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={() => onSave(editedCourse)}>Save</Button>
        </DialogActions>
      </Dialog>
    )
  }
}