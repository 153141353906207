import React, { Component } from "react";
import { Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { apiConfig } from "../../../../config/api.config";
import { addCourse, addCoursePrice, editCourse, getCategories, getCompetencies, getCourses } from "../../../../redux/courseCrud";
import { toCamel } from "../../../../utils";
import NumberFormat from "react-number-format";

import "../../../styles/imageOverlay.css";
import { CreateCourseDialog } from "./CreateCourseDialog";
import { EditCourseDialog } from "./EditCourseDialog";
import { ViewLessonsDialog } from "./ViewLessonsDialog";
import { ViewCoursePricesDialog } from "./ViewCoursePricesDialog";
import { CourseImagesDialog } from "./CourseImagesDialog";
import { ViewExamsDialog } from "./ViewExamsDialog";

const ITEM_HEIGHT = 48

class CoursePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      categories: [],
      competencies: [],
      editedCourse: {},
      showCreateDialog: false,
      showEditDialog: false,
      showImagesDialog: false,
      showViewLessonsDialog: false,
      showViewCoursePricesDialog: false,
      showViewExamsDialog: false,
      anchorEl: null,
    };
  }

  componentDidMount() {
    this.fetchCourses();
    this.fetchCategories();
    this.fetchCompetencies();
  }

  fetchCourses() {
    getCourses()
      .then(({ data }) => {
        if (data.Success) {
          this.setState({ courses: toCamel(data.Data) });
        }
      });
  }

  fetchCategories() {
    getCategories()
      .then(({ data }) => {
        if (data.Success) {
          this.setState({ categories: toCamel(data.Data) });
        }
      });
  }

  fetchCompetencies() {
    getCompetencies()
      .then(({ data }) => {
        if (data.Success) {
          const payload = toCamel(data.Data);
          this.setState({ competencies: payload.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)), });
        }
      });
  }

  handleCreateDialogClick() {
    this.setState({
      showCreateDialog: true
    });
  }

  handleCreateDialogClose() {
    this.setState({
      showCreateDialog: false
    });
  }

  handleCreateDialogSave(newCourse) {
    addCourse(newCourse)
      .then(({ data }) => {
        if (data.Success) {
          const course = toCamel(data.Data);
          if (course.type === "P") {
            const coursePrice = {
              courseID: course.id,
              targetCountry: course.targetCountry,
              fromTime: JSON.stringify(new Date()),
              currency: course.currency,
              priority: 1,
              value: course.price,
              baseValue: course.baseValue,
              name: "Harga Normal",
              description: ""
            }
            addCoursePrice(coursePrice)
              .then(({ data }) => {
                if (!data.Success) {
                  alert("Penambahan harga gagal. Silahkan lakukan penambahan harga di menu prices.");
                }
              });
          }

          this.setState({
            showCreateDialog: false
          });
          this.fetchCourses();
        }
      });
  }

  handleEditCourseDialogClick(course) {
    this.setState({
      showEditDialog: true,
      editedCourse: { ...course }
    });
  }

  handleEditCourseDialogClose() {
    this.setState({
      showEditDialog: false
    });
  }

  handleEditCourseDialogSave(course) {
    editCourse(course.id, course)
      .then(({ data }) => {
        if (data.Success) {
          this.setState({
            showEditDialog: false
          });
          this.fetchCourses();
        }
      });
  }

  handleCourseImagesDialogClose() {
    this.setState({
      showImagesDialog: false
    });
  }

  handleViewLessonsDialogClick(course) {
    this.setState({
      showViewLessonsDialog: true,
      editedCourse: course
    });
  }

  handleViewLessonsDialogClose() {
    this.setState({
      showViewLessonsDialog: false,
    });
  }

  handleViewCoursePricesDialogClick(course) {
    this.setState({
      showViewCoursePricesDialog: true,
      editedCourse: course
    });
  }

  handleViewCoursePricesDialogClose() {
    this.setState({
      showViewCoursePricesDialog: false
    });
  }

  handleViewExamsDialogClick(course) {
    this.setState({
      showViewExamsDialog: true,
      editedCourse: course
    });
  }

  handleViewExamsDialogClose() {
    this.setState({
      showViewExamsDialog: false
    });
  }

  render() {
    const { courses, categories, competencies, showCreateDialog } = this.state;
    const { showEditDialog, editedCourse } = this.state;
    const { showImagesDialog, showViewLessonsDialog, showViewCoursePricesDialog, showViewExamsDialog } = this.state;
    const { anchorEl } = this.state;
    const openMenu = Boolean(anchorEl);

    return (
      <>
        <div className={`card card-custom `}>
          {/* Head */}
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">Course</span>
            </h3>
            <div className="card-toolbar">
              <Button className="btn btn-info font-weight-bolder font-size-sm mr-3" onClick={this.handleCreateDialogClick.bind(this)}>Create</Button>
            </div>
          </div>
          {/* Body */}
          <div className="card-body pt-0 pb-3">
            <div className="tab-content">
              <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <tr>
                    <thead>
                      <tr className="text-left text-uppercase">
                        <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Title</span></th>
                        <th style={{ minWidth: "100px" }}>Series<br />Major</th>
                        <th style={{ minWidth: "100px" }}>Country<br />Language</th>
                        <th style={{ minWidth: "100px" }}>#Lessons</th>
                        <th style={{ minWidth: "100px" }}>Price<br />Type</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {courses.map(course =>
                        <tr>
                          <td className="pl-0 py-8">
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-50 symbol-light mr-4">
                                <span className="symbol-label">
                                  <img alt={course.coverImageRID} src={`${apiConfig.apiUrl.course}/image/course/${course.coverImageRID}/thumb`} height={40}></img>
                                </span>
                              </div>
                              <div>
                                <a href={`/management/course/participant?courseId=${course.id}`} className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{course.title}</a>
                                <span className="text-muted font-weight-bold d-block">{course.topic} ({course.category})</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {course.series}
                            </span>
                            <span className="text-muted font-weight-bold d-block">{course.major}</span>
                          </td>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {course.targetCountry}
                            </span>
                            <span className="text-muted font-weight-bold d-block">{course.language}</span>
                          </td>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              {course.lessonCount}
                            </span>
                          </td>
                          <td>
                            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                              <NumberFormat
                                value={course.activePrice}
                                displayType="text"
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix={course.currency}
                              />
                            </span>
                            <span className="text-muted font-weight-bold d-block">{course.type} ({course.type === "P" ? "Plus" : "Free"})</span>
                          </td>
                          <td className="pr-0 text-right">
                            <div>
                              <IconButton
                                aria-label="More"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(e) => {
                                  this.setState({
                                    anchorEl: e.currentTarget,
                                    editedCourse: { ...course }
                                  });
                                }}>
                                  <MoreVertIcon />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={openMenu}
          onClose={() => this.setState({ anchorEl: null })}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200,
            },
          }}
        >
          <MenuItem onClick={() => {
            this.setState({
              showEditDialog: true,
              anchorEl: null
            });
          }}>
            Edit
          </MenuItem>
          <MenuItem onClick={() => {
            this.setState({
              showImagesDialog: true,
              anchorEl: null
            });
          }}>
            Images
          </MenuItem>
          <MenuItem onClick={() => {
            this.setState({
              showViewLessonsDialog: true,
              anchorEl: null
            });
          }}>
            Lessons
          </MenuItem>
          <MenuItem onClick={() => {
            this.setState({
              showViewCoursePricesDialog: true,
              anchorEl: null
            });
          }}>
            Prices
          </MenuItem>
          <MenuItem onClick={() => {
            this.setState({
              showViewExamsDialog: true,
              anchorEl: null
            });
          }}>
            Exams
          </MenuItem>
        </Menu>

        <CreateCourseDialog show={showCreateDialog} categories={categories} competencies={competencies} onClose={this.handleCreateDialogClose.bind(this)} onSave={this.handleCreateDialogSave.bind(this)} />
        <EditCourseDialog course={editedCourse} show={showEditDialog} categories={categories} competencies={competencies} onClose={this.handleEditCourseDialogClose.bind(this)} onSave={this.handleEditCourseDialogSave.bind(this)} />
        <CourseImagesDialog course={editedCourse} show={showImagesDialog} onClose={this.handleCourseImagesDialogClose.bind(this)} />
        <ViewLessonsDialog course={editedCourse} show={showViewLessonsDialog} onClose={this.handleViewLessonsDialogClose.bind(this)} />
        <ViewCoursePricesDialog course={editedCourse} show={showViewCoursePricesDialog} onClose={this.handleViewCoursePricesDialogClose.bind(this)} />
        <ViewExamsDialog course={editedCourse} show={showViewExamsDialog} onClose={this.handleViewExamsDialogClose.bind(this)} />
      </>
    );
  }
}

export default CoursePage;