import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getAcsUserProfileByToken, logout } from "./authCrud";
import { toCamel } from "../utils";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
};

const initialAuthState = {
  authToken: undefined,
  tokenExpiryTime: undefined,
  user: undefined,
};

export const reducer = persistReducer(
  { storage, key: "playbook-admin-auth", whitelist: ["user", "authToken", "tokenExpiryTime"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, tokenExpiryTime } = action.payload;
        return { authToken, tokenExpiryTime, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        logout();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken, tokenExpiryTime) => ({ type: actionTypes.Login, payload: { authToken, tokenExpiryTime } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({ type: actionTypes.UserRequested }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: result } = yield getAcsUserProfileByToken();
    console.log(result);

    if (result.Success) {
      yield put(actions.fulfillUser(toCamel(result.Data)));
    }
  });
}
