import React, { Component } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Paper } from "@material-ui/core";
import { getExams, getExamType } from "../../../../redux/examCrud";
import { toCamel } from "../../../../utils";
import { CreateLessonDialog } from "./CreateLessonDialog";
import { EditLessonDialog } from "./EditLessonDialog";
import { EditExamTypeDialog } from "./EditExamTypeDialog";

export class ViewExamsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateExamDialog: false,
      showEditExamTypeDialog: false,
      exams: [],
      examTypes: [],
      editedExam: {},
      editedExamType: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.course.id !== this.props.course.id) {
      this.fetchExams(this.props.course.id);
    }
  }

  fetchExams() {
    const { course } = this.props;
    const Filters = { CourseID: course.id };
    getExams({ Filters })
      .then(({ data }) => {
        if (data.Success) {
          const payload = toCamel(data.Data);
          this.setState({
            exams: payload,
            examTypes: []
          });
          this.fetchExamTypes(payload);
        }
      });
  }

  fetchExamTypes(exams) {
    exams.forEach((exam) => {
      getExamType(exam.examTypeID)
        .then(({ data }) => {
          if (data.Success) {
            const { examTypes } = this.state;
            examTypes.push(toCamel(data.Data));
            this.setState({ examTypes });
          }
        });
    });
  }

  handleCreateLessonDialogClick() {
    this.setState({
      showCreateLessonDialog: true
    });
  }

  handleCreateLessonDialogClose() {
    this.setState({
      showCreateLessonDialog: false
    });
  }

  handleCreateLessonDialogSave() {
    this.fetchLessons();
    this.setState({
      showCreateLessonDialog: false
    });
  }

  handleEditExamDialogClick(examType) {
    this.setState({
      editedExamType: examType,
      showEditExamTypeDialog: true
    });
  }

  handleEditExamDialogClose() {
    this.setState({
      showEditExamTypeDialog: false
    });
  }

  handleEditLessonDialogSave() {
    this.fetchLessons();
    this.setState({
      showEditLessonDialog: false
    });
  }

  render() {
    const { show } = this.props;
    const { onClose } = this.props;
    const { showCreateLessonDialog, showEditExamTypeDialog } = this.state;
    const { examTypes, editedExamType } = this.state;

    return (
      <>
        <Dialog open={show} onClose={onClose} maxWidth="lg">
          <DialogTitle>Exams</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item lg={12}>
                <div className="m-2">
                  {/* Head */}
                  <div className="card-header border-0 py-5">
                    <div className="card-toolbar">
                      <button className="btn btn-info font-weight-bolder font-size-sm mr-3" onClick={this.handleCreateLessonDialogClick.bind(this)}>Add Exam</button>
                    </div>
                  </div>
                  {/* Body */}
                  <div className="card-body pt-0 pb-3">
                    <div className="tab-content">
                      <div className="table-responsive">
                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                          <thead>
                            <tr className="text-left text-uppercase">
                              <th style={{ minWidth: "100px" }}>No</th>
                              <th className="pl-7" style={{ minWidth: "100px" }}><span className="text-dark-75">Exam Name</span></th>
                              <th style={{ minWidth: "100px" }}>Duration (mins)<br />Type</th>
                              <th style={{ minWidth: "100px" }}>Description</th>
                              <th style={{ minWidth: "100px" }}>Instruction</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            { examTypes.map((exam, idx) =>
                              <tr>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {idx + 1}
                                  </span>
                                </td>
                                <td className="pl-0 py-8">
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{exam.examName}</a>
                                      <span className="text-muted font-weight-bold d-block">{exam.hash}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {exam.examTime}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {exam.description}
                                  </span>
                                </td>
                                <td>
                                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {exam.instruction}
                                  </span>
                                </td>
                                <td className="pr-0 text-right">
                                  <Button className="btn btn-light-success font-weight-bolder font-size-sm mr-2" onClick={() => this.handleEditExamDialogClick(exam)}>Edit</Button>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </Dialog>

        <EditExamTypeDialog examType={editedExamType} show={showEditExamTypeDialog} onClose={this.handleEditExamDialogClose.bind(this)} />
      </>
    )
  }
}